section.root {
  display: flex;
  gap: 60px;
  max-width: 100vw !important;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 12px 0px 0px;
  justify-content: center;
  margin: 0;
}

.root-about-outscal {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 50px;
  max-width: 300px;
  min-width: 250px;
  height: 100%;
  justify-content: space-between;
}

.outscal-logo {
  padding: 0px;
  object-fit: contain;
  display: flex;
  flex-direction: row;
}

.about-outscal-descp {
  width: 100%;
  font-size: 16px;
  line-height: 25px;
  color: #525769;
}

.footer-headings {
  font-size: 18px;
  font-weight: 600;
}

.root-company {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
  max-width: 300px;
  height: 100%;
}

.root-key-links {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
  max-width: 300px;
  height: 100%;
}

.links-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.links {
  font-size: 16px;
  color: #525769;
}

.root-contact-us {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  max-width: 300px;
  height: 100%;
  justify-content: space-between;
}

.email-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  color: #525769;
  gap: 5px;
}

.email-icon {
  font-size: 25px;
  fill: #f3ca20;
}

.contact-email-span {
  margin-bottom: 4px;
}

.social-media-icons-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.social-media-icon {
  font-size: 25px;
  color: #525769;
  cursor: pointer;
}

.social-media-icon:hover {
  color: #3f57cb;
  scale: 1.05;
}

.row-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  color: #525769;
}

@media (max-width: 767px) {
  .root {
    padding-bottom: 7rem !important;
    gap: 0 !important;
  }
}

@media (max-width: 920px) {
  .root {
    height: auto;
    gap: 0px;
    text-align: left;
  }

  .root-contact-us {
    justify-content: center;
  }

  .root-key-links {
    display: none;
  }

  .root-about-outscal {
    display: none;
  }
}
